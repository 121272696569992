import getConfig from "next/config";
import { useSearchParams } from "next/navigation";
import { useRouter } from "next/router";
import { ReactNode, useEffect } from "react";
import AppLayout from "ui/src/components/AppLayout";
import { SignIn } from "ui/src/components/SignIn";
import { useToast } from "ui/src/components/Toast";
import { LOGIN_CLICK } from "ui/src/constants/mixpanel.constants";
import { AuthProvider, useAuthState } from "ui/src/context/CaptionsAuthContext";
import { useMixpanel } from "ui/src/hooks/useMixpanel";
import { useSupportedBrowserVersion } from "ui/src/hooks/useSupportedBrowserVersion";

const LEGAL_CENTER_URL = "https://www.captions.ai/legal/terms";

const Login = () => {
  const { signIn } = useAuthState();
  const toast = useToast();
  const router = useRouter();
  const isSupportedBrowserVersion = useSupportedBrowserVersion();

  const searchParams = useSearchParams();
  const { track } = useMixpanel();
  const errorSearchParam = searchParams?.get("error");

  const instrumentedSignIn = (provider: AuthProvider) => async () => {
    track(LOGIN_CLICK, { type: provider });
    return await signIn(provider);
  };

  const handleLegalCenterClick = () => {
    window.open(LEGAL_CENTER_URL, "_blank");
  };

  useEffect(() => {
    if (errorSearchParam === "AccessDenied") {
      toast.add("Access denied: Unable to sign in.", {
        duration: 5000,
        severity: "error",
      });
      // Remove search param
      router.push("/login");
    }
  }, [errorSearchParam]);

  useEffect(() => {
    if (!isSupportedBrowserVersion) {
      toast.add(
        "This version of your browser is not supported. Please update your browser or switch to a different one to continue.",
        {
          severity: "error",
          noAction: true,
          style: {
            width: "867px",
            maxWidth: "90vw",
          },
          duration: Infinity,
        }
      );
    }
  }, [isSupportedBrowserVersion]);

  return (
    <SignIn
      hideTopBar
      onLegalCenterClick={handleLegalCenterClick}
      onGoogleClick={instrumentedSignIn("google")}
      onAppleClick={instrumentedSignIn("apple")}
      onMicrosoftClick={instrumentedSignIn("azure-ad")}
      version={getConfig().publicRuntimeConfig.version}
      areActionsBlocked={!isSupportedBrowserVersion}
    />
  );
};

Login.requireAuth = false;

Login.getLayout = (page: ReactNode) => (
  <AppLayout noPadding hideTopBar>
    {page}
  </AppLayout>
);

export default Login;
